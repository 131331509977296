:root {
  --operation-modal-max-width: 140rem;
  --operation-modal-min-width: 120rem;
  --operation-modal-waveform-height: 13rem;
}

.modal-operation.wide {
  --operation-modal-max-width: 280rem;
  --operation-modal-min-width: 240rem;
  --operation-modal-waveform-height: 52rem;
}

.modal-operation .modal__window {
  max-width: var(--operation-modal-max-width);
  min-width: var(--operation-modal-min-width);
}

.device-connected {
  background-color: #bad0e7;
}

.capture-terminated {
  background-color: #dedede;
  color: #4f4f4f;
}

.audio-chunk {
  color: red;
  font-weight: bold;
}

.clip-ui__waveform .base__inner {
  height: var(--operation-modal-waveform-height);
}

.clip-ui__bleep .bleep {
  user-select: none;
}

.clip-ui__bleep .bleep::after, .clip-ui__bleep .cut::after {
  content: "";
  z-index: 10;
  position: absolute;
  display: block;
  top: calc(3.2rem + 1.6rem);
  left: 0;
  height: var(--operation-modal-waveform-height);
  width: 100%;
  //  background-color: rgba(black, 0.25);
  // border-left: 1px solid rgba(black, 0.4);
  // border-right: 1px solid rgba(black, 0.4);
}

.clip-ui__bleep {
  .knob, .delete, .cut, .bleep {
    //transition: background-color 0.5s !important;
    //transition: background 0.2s !important;
    transition: none !important;
    user-select: none;
    &::after {
      transition: none !important;
    }
  }
}

.trim-slider {
  user-select: none;
  transition: none;
  span {
    user-select: none;
    transition: none;
  }
}

.livegraphics .scroll-box-s {
  //z-index: 1;
}

.special-name::first-letter {
  color: #cb4e42;
  margin-right: 0.2em;
}

.locked-on .special-name::first-letter {
  color: white;
}

#timeline-frame {
  overflow: hidden;
  position: relative;
  .timeline-frame-inner {
    position: absolute;
    cursor: grab;
    user-select: none;
    min-width: 100vw;
  }
}

.time-line-view .time-width__waveform {
  padding-left: 0;
  padding-right: 0;
  transition: 0.3s;
}

.time-line-view .timeline-frame-inner button {
  .block {
    // opacity: 1;
  }

  &.block-hidden {
    min-width: auto;
    .block {
      display: none;
    }
  }
}

.time-line-view .timeline-frame-inner button:hover {
  z-index: 10;
  min-width: 14rem;
  .block {
    right: 0;
    display: block;
    position: absolute;
  }
}

.time-width .time-width__inner {
  left: auto;
  min-width: 100vw;
  transition: all 0.3s;
}

time-width__scale__lap {
  transition: right 0.3s;
}

#timeline-frame {
  overflow: hidden;
  position: relative;
  .timeline-frame-inner {
    position: absolute;
    cursor: grab;
    user-select: none;
    min-width: 100vw;
  }
}

.time-line-view .time-width__waveform {
  padding-left: 0;
  padding-right: 0;
  transition: 0.3s;
}

.time-line-view .timeline-frame-inner button {
  .block {
    // opacity: 1;
  }

  &.block-hidden {
    min-width: auto;
    .block {
      display: none;
    }
  }
}

.time-line-view .timeline-frame-inner button:hover {
  z-index: 10;
  min-width: 14rem;
  .block {
    right: 0;
    display: block;
    position: absolute;
  }
}

.time-width .time-width__inner {
  left: auto;
  min-width: 100vw;
  transition: all 0.3s;
}

.time-width__scale__lap {
  transition: right 0.3s;
}

.time-width__waveform__inner {
  user-select: none;
}

.special-name::first-letter {
  color: #cb4e42;
  margin-right: 0.2em;
}

.locked-on .special-name::first-letter {
  color: white;
}

.block-view {
  .driver-wrap {
    .block-area__base {
      background-color: #571c16 !important;
    }
    .driver-wrap__history-area {
      background-color: #666 !important;
    }
    &.bg-alternate {
      .block-area__base {
        background-color: #78413b !important;
      }
      .driver-wrap__history-area {
      background-color: #7b7b7b !important;
    }

    }
  }
}

.livegraphics {
  .num-box span:before {
    content: "";
    display: block;
    width: 100%;
    height: 13%;
    position: absolute;
    z-index: 50;
    background: url(images/icon_hrc.png) no-repeat 50% 50%/contain;
    top: 12%;
    left: 0;
  }
  .engine-hrc .num-box span:before {
    background-image: url(images/icon_hrc.png) !important;
  }
  .engine-hrc.locked-on .num-box span:before {
    background-image: url(images/icon_hrc_locked.png) !important;
  }
  .engine-tgr .num-box span:before {
    background-image: url(images/icon_gr.png) !important;
  }
  .engine-tgr.locked-on .num-box span:before {
    background-image: url(images/icon_gr_locked.png) !important;
  }
}

.time-line-view {
  .time-width__driver-wrap {
    background: #000 !important;
  }

  .time-width__driver-wrap.bg-alternate {
    background: rgba(0,0,0,0.8) !important;
  }

  .time-width__driver-wrap {
    background: #000 !important;
  }
}
