// Team Background Definition

%team-bg-kondo {
  background-color: rgba(50, 60, 159, 1.00);
}

%team-bg-dandelion {
  background-color: white;
}

%team-bg-kcmg {
  background-color: rgba(30, 198, 232, 1.00);
}

%team-bg-threebond {
  background-color: rgba(223, 98, 22, 1.00);
  background: repeating-linear-gradient(-135deg, rgba(223, 98, 22, 1.00), rgba(223, 98, 22, 1.00) 2rem, black 2rem, black 4rem);
}

%team-bg-rookie {
  background-color: black;
  background-image: repeating-linear-gradient(45deg,
    rgba(255, 255, 255, 0.50),
    rgba(255, 255, 255, 0.50) 0.5rem,
    transparent 0,
    transparent 1rem),
  repeating-linear-gradient(315deg,
      rgba(223, 47, 38, 0.50),
      rgba(223, 47, 38, 0.50) 0.5rem,
      transparent 0,
      transparent 1rem);
}

%team-bg-mugen {
  background-color: rgba(178, 158, 69, 1.00);
}

%team-bg-impul {
  background: repeating-linear-gradient(-135deg, black, black 2rem, white 2rem, white 10rem);
}

%team-bg-toms {
  background-color: rgba(31, 125, 91, 1.00);
}

%team-bg-inging {
  background-color: rgba(178, 174, 174, 1.00);
}

%team-bg-bmax {
  background-color: rgba(31, 77, 133, 1.00);
}

%team-bg-tgm {
  background: repeating-linear-gradient(-135deg, rgba(249, 46, 139, 1.00), rgba(249, 46, 139, 1.00) 2rem, rgba(90, 90, 90, 1.00) 2rem, rgba(90, 90, 90, 1.00) 10rem);
}

%team-bg-nakajima {
  background-color: rgba(251, 125, 9, 1.00);
}

// Team Background

$teams: kondo, dandelion, kcmg, threebond, rookie, mugen, impul, toms, inging, bmax, tgm, nakajima;

.livegraphics .driver-wrap, .livegraphics .locked-on-area__unit, .livegraphics .driver-wrap, #modal-operation .clip-info {
  @each $team in $teams {
    &.team-#{$team} .photo {
      @extend %team-bg-#{$team};
    }
  }
}
