:root {
  --operation-modal-max-width: 140rem;
  --operation-modal-min-width: 120rem;
  --operation-modal-waveform-height: 13rem;
}

.modal-operation.wide {
  --operation-modal-max-width: 280rem;
  --operation-modal-min-width: 240rem;
  --operation-modal-waveform-height: 52rem;
}

.modal-operation .modal__window {
  max-width: var(--operation-modal-max-width);
  min-width: var(--operation-modal-min-width);
}

.device-connected {
  background-color: #bad0e7;
}

.capture-terminated {
  background-color: #dedede;
  color: #4f4f4f;
}

.audio-chunk {
  color: red;
  font-weight: bold;
}

.clip-ui__waveform .base__inner {
  height: var(--operation-modal-waveform-height);
}

.clip-ui__bleep .bleep {
  user-select: none;
}

.clip-ui__bleep .bleep::after, .clip-ui__bleep .cut::after {
  content: "";
  z-index: 10;
  position: absolute;
  display: block;
  top: 4.8rem;
  left: 0;
  height: var(--operation-modal-waveform-height);
  width: 100%;
}

.clip-ui__bleep .knob, .clip-ui__bleep .delete, .clip-ui__bleep .cut, .clip-ui__bleep .bleep {
  transition: none !important;
  user-select: none;
}
.clip-ui__bleep .knob::after, .clip-ui__bleep .delete::after, .clip-ui__bleep .cut::after, .clip-ui__bleep .bleep::after {
  transition: none !important;
}

.trim-slider {
  user-select: none;
  transition: none;
}
.trim-slider span {
  user-select: none;
  transition: none;
}

.special-name::first-letter {
  color: #cb4e42;
  margin-right: 0.2em;
}

.locked-on .special-name::first-letter {
  color: white;
}

#timeline-frame {
  overflow: hidden;
  position: relative;
}
#timeline-frame .timeline-frame-inner {
  position: absolute;
  cursor: grab;
  user-select: none;
  min-width: 100vw;
}

.time-line-view .time-width__waveform {
  padding-left: 0;
  padding-right: 0;
  transition: 0.3s;
}

.time-line-view .timeline-frame-inner button.block-hidden {
  min-width: auto;
}
.time-line-view .timeline-frame-inner button.block-hidden .block {
  display: none;
}

.time-line-view .timeline-frame-inner button:hover {
  z-index: 10;
  min-width: 14rem;
}
.time-line-view .timeline-frame-inner button:hover .block {
  right: 0;
  display: block;
  position: absolute;
}

.time-width .time-width__inner {
  left: auto;
  min-width: 100vw;
  transition: all 0.3s;
}

time-width__scale__lap {
  transition: right 0.3s;
}

#timeline-frame {
  overflow: hidden;
  position: relative;
}
#timeline-frame .timeline-frame-inner {
  position: absolute;
  cursor: grab;
  user-select: none;
  min-width: 100vw;
}

.time-line-view .time-width__waveform {
  padding-left: 0;
  padding-right: 0;
  transition: 0.3s;
}

.time-line-view .timeline-frame-inner button.block-hidden {
  min-width: auto;
}
.time-line-view .timeline-frame-inner button.block-hidden .block {
  display: none;
}

.time-line-view .timeline-frame-inner button:hover {
  z-index: 10;
  min-width: 14rem;
}
.time-line-view .timeline-frame-inner button:hover .block {
  right: 0;
  display: block;
  position: absolute;
}

.time-width .time-width__inner {
  left: auto;
  min-width: 100vw;
  transition: all 0.3s;
}

.time-width__scale__lap {
  transition: right 0.3s;
}

.time-width__waveform__inner {
  user-select: none;
}

.special-name::first-letter {
  color: #cb4e42;
  margin-right: 0.2em;
}

.locked-on .special-name::first-letter {
  color: white;
}

.block-view .driver-wrap .block-area__base {
  background-color: #571c16 !important;
}
.block-view .driver-wrap .driver-wrap__history-area {
  background-color: #666 !important;
}
.block-view .driver-wrap.bg-alternate .block-area__base {
  background-color: #78413b !important;
}
.block-view .driver-wrap.bg-alternate .driver-wrap__history-area {
  background-color: #7b7b7b !important;
}

.livegraphics .num-box span:before {
  content: "";
  display: block;
  width: 100%;
  height: 13%;
  position: absolute;
  z-index: 50;
  background: url(images/icon_hrc.png) no-repeat 50% 50%/contain;
  top: 12%;
  left: 0;
}
.livegraphics .engine-hrc .num-box span:before {
  background-image: url(images/icon_hrc.png) !important;
}
.livegraphics .engine-hrc.locked-on .num-box span:before {
  background-image: url(images/icon_hrc_locked.png) !important;
}
.livegraphics .engine-tgr .num-box span:before {
  background-image: url(images/icon_gr.png) !important;
}
.livegraphics .engine-tgr.locked-on .num-box span:before {
  background-image: url(images/icon_gr_locked.png) !important;
}

.time-line-view .time-width__driver-wrap {
  background: #000 !important;
}
.time-line-view .time-width__driver-wrap.bg-alternate {
  background: rgba(0, 0, 0, 0.8) !important;
}
.time-line-view .time-width__driver-wrap {
  background: #000 !important;
}

.livegraphics .driver-wrap.team-kondo .photo, .livegraphics .locked-on-area__unit.team-kondo .photo, #modal-operation .clip-info.team-kondo .photo {
  background-color: rgb(50, 60, 159);
}

.livegraphics .driver-wrap.team-dandelion .photo, .livegraphics .locked-on-area__unit.team-dandelion .photo, #modal-operation .clip-info.team-dandelion .photo {
  background-color: white;
}

.livegraphics .driver-wrap.team-kcmg .photo, .livegraphics .locked-on-area__unit.team-kcmg .photo, #modal-operation .clip-info.team-kcmg .photo {
  background-color: rgb(30, 198, 232);
}

.livegraphics .driver-wrap.team-threebond .photo, .livegraphics .locked-on-area__unit.team-threebond .photo, #modal-operation .clip-info.team-threebond .photo {
  background-color: rgb(223, 98, 22);
  background: repeating-linear-gradient(-135deg, rgb(223, 98, 22), rgb(223, 98, 22) 2rem, black 2rem, black 4rem);
}

.livegraphics .driver-wrap.team-rookie .photo, .livegraphics .locked-on-area__unit.team-rookie .photo, #modal-operation .clip-info.team-rookie .photo {
  background-color: black;
  background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5) 0.5rem, transparent 0, transparent 1rem), repeating-linear-gradient(315deg, rgba(223, 47, 38, 0.5), rgba(223, 47, 38, 0.5) 0.5rem, transparent 0, transparent 1rem);
}

.livegraphics .driver-wrap.team-mugen .photo, .livegraphics .locked-on-area__unit.team-mugen .photo, #modal-operation .clip-info.team-mugen .photo {
  background-color: rgb(178, 158, 69);
}

.livegraphics .driver-wrap.team-impul .photo, .livegraphics .locked-on-area__unit.team-impul .photo, #modal-operation .clip-info.team-impul .photo {
  background: repeating-linear-gradient(-135deg, black, black 2rem, white 2rem, white 10rem);
}

.livegraphics .driver-wrap.team-toms .photo, .livegraphics .locked-on-area__unit.team-toms .photo, #modal-operation .clip-info.team-toms .photo {
  background-color: rgb(31, 125, 91);
}

.livegraphics .driver-wrap.team-inging .photo, .livegraphics .locked-on-area__unit.team-inging .photo, #modal-operation .clip-info.team-inging .photo {
  background-color: rgb(178, 174, 174);
}

.livegraphics .driver-wrap.team-bmax .photo, .livegraphics .locked-on-area__unit.team-bmax .photo, #modal-operation .clip-info.team-bmax .photo {
  background-color: rgb(31, 77, 133);
}

.livegraphics .driver-wrap.team-tgm .photo, .livegraphics .locked-on-area__unit.team-tgm .photo, #modal-operation .clip-info.team-tgm .photo {
  background: repeating-linear-gradient(-135deg, rgb(249, 46, 139), rgb(249, 46, 139) 2rem, rgb(90, 90, 90) 2rem, rgb(90, 90, 90) 10rem);
}

.livegraphics .driver-wrap.team-nakajima .photo, .livegraphics .locked-on-area__unit.team-nakajima .photo, #modal-operation .clip-info.team-nakajima .photo {
  background-color: rgb(251, 125, 9);
}

